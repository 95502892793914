export const CREATE_FEATURE_MUTATION = gql`
  mutation CreateFeature($input: CreateFeatureInput!) {
    createFeature(input: $input) {
      id
      name
      description
      enabled
    }
  }
`

export const UPDATE_FEATURE_MUTATION = gql`
  mutation UpdateFeature($id: Int!, $input: UpdateFeatureInput!) {
    updateFeature(id: $id, input: $input) {
      id
      name
      description
      enabled
    }
  }
`

export const DELETE_FEATURE_MUTATION = gql`
  mutation DeleteFeature($id: Int!) {
    deleteFeature(id: $id) {
      id
    }
  }
`
